import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from "@mui/material/CssBaseline";
import { Global, css } from '@emotion/react'
import App from './App';


ReactDOM.createRoot(document.getElementById('app')!).render(
  <React.StrictMode>
    <Global styles={css`
      html, body {
        min-height: 100vh;
      }
    
      body {
        display: flex;
        flex-direction: column;
      }
    
      #app {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    `} />
    <CssBaseline />
    <App />
  </React.StrictMode>,
)

