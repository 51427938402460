import { FC } from 'react';
import { ReactComponent as Smudge } from './assets/svg/Smudge.svg'
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import Typography from '@mui/material/Typography'

export const animateMoveUp20 = keyframes`
    from { transform: translateY(-10px); }
    to { transform: translateY(0); }
`

const ContainerStyled = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #5402a7;
  overflow: hidden;
  padding: 10px 20px;

  svg {
    min-height: 250px;
    min-width: 250px;
    height: 50%;
    width: 50%;
    animation: ${animateMoveUp20} linear 1.5s infinite alternate;
  }
`;

const RotatedContainerStyled = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(9deg);
  position: relative;
`;


const App: FC = () => {
  return (
    <ContainerStyled>
      <Typography variant='h2' color='white' textAlign='center'>see u soon! 👀</Typography>
      <RotatedContainerStyled>
        <Smudge />
      </RotatedContainerStyled>
    </ContainerStyled>
  );
}

export default App;
